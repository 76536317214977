import React from "react";
import { Link, graphql } from "gatsby";
import Layout from "../components/layout";
// import Image from "../components/image"
import SEO from "../components/seo";

const TagList = ({ pageContext, data }) => {
	const { tag } = pageContext;
	const { edges, totalCount } = data.allMarkdownRemark;
	const tagHeader = `${totalCount} post${
		totalCount === 1 ? "" : "s"
	} tagged with "${tag}"`;

	return (
		<Layout>
			<SEO title={tagHeader} />

			<div>
				<h1>{tagHeader}</h1>
				<ul>
					{edges.map(({ node }) => {
						const { title, url } = node.frontmatter;
						return (
							<li key={url}>
								<Link to={url}>{title}</Link>
							</li>
						);
					})}
				</ul>
				{/*
                      This links to a page that does not yet exist.
                      You'll come back to it!
                    */}
				<Link to="/tags">All tags</Link>
			</div>
		</Layout>
	);
};

export default TagList;

export const pageQuery = graphql`
  query($tag: String) {
    allMarkdownRemark(
      limit: 2000
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { frontmatter: { tags: { in: [$tag] } } }
    ) {
      totalCount
      edges {
        node {
          frontmatter {
            title
            url
          }
        }
      }
    }
  }
`;


// export const pageQuery = graphql`
// 	query blogListQuery($skip: Int!, $limit: Int!) {
//     	allMarkdownRemark(
// 			sort:{ order:DESC, fields:[frontmatter___date]}
// 			limit: $limit
// 			skip: $skip
// 		) {
// 			edges {
// 				node {
// 					id
// 					frontmatter {
// 						date(formatString:"DD MMMM YYYY")
// 						url
// 						title
// 					}
// 				}
// 			}
//       	}
//     }
// `;

